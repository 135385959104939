import React from 'react';
import {Input,Button,Alert,Row,Col,Table,Modal} from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import history from '../history';
import {reactLocalStorage} from 'reactjs-localstorage';
import NavigationBar from '../components/navigationbar'
import LoadingScreen from '../components/LoadingScreen';
import {copyDiv} from './global.js'
import ReactPaginate from 'react-paginate';

import * as GLOBAL from '../pages/global'
import WalletLog from '../components/walletLog.jsx';

import { AiOutlineCheckSquare, AiOutlineCloseSquare } from 'react-icons/ai';
import {BsFillInfoCircleFill} from 'react-icons/bs'
import {MdNavigateBefore,MdNavigateNext} from 'react-icons/md'

export default class CompanyAchievement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,

            isStaff:false,
            achievementData:[],
            monthTotalSales:0,
            monthTotalCommission:0,
            monthTotalReferral:0,
            monthTotalCost:0,
            monthTotalProfitSharing:0,
            monthEmployeePGArray:[],
            monthOutsideAgentPGArray:[],
            monthOtherPGArray:[],

            companyDetail:null,

            walletLogList:[],
            walletLogModal:false,
            walletLogPageCount:0,
            walletLogPage:1,
            loadingFromChild:null, 

            profitSharingLogList:[],
            profitSharingModal:false,
            profitSharingPageCount:0,
            profitSharingPage:1,

            censoredColumn:[],

            payToCompany:false,
            viewAsIdentity:"Staff",
        }
        this.divRef = React.createRef();
        this.month=["January","February","March","April","May","June","July","August","September","October","November","December"]
        this.currentMonth=new Date().getFullYear()+"-"+('0' + (new Date().getMonth()+1)).slice(-2)
    }

    componentDidMount=()=>{
        axios.defaults.headers.common.Authorization = `Token ${reactLocalStorage.get("token")}`
        this.launchFunc()
    }

    launchFunc=()=>{
        //verify that user is logged in
        if(!reactLocalStorage.get("token")){
            history.push("./")
            return
        }

        //parse
        let pars=window.location.search.substr(1)
        let inputSplit=pars.split("&")
        for(let input of inputSplit){
            let param=input.split("=")[0]
            let value=input.split("=")[1]
            if(param=="companyid"){  //seek user by id
                this.companyID=value
            }else if(param=="currentmonth"){
                this.currentMonth=value
            }
        }

        if(this.companyID){
            this.loadAgentCompanyAchievement(this.companyID)
        }else{
            this.loadAgentCompanyAchievement()
        }
    }

    loadAgentCompanyAchievement=(companyID="")=>{
        let payload={month:this.currentMonth,}
        if(companyID!=""){ //load other company achievement, use by admin
            payload={
                companyID:companyID,
                month:this.currentMonth,
                viewAsIdentity:this.state.viewAsIdentity,
            }
        }
        this.setState({loading:true})
        axios
            .post(`sales/loadagentcompanyachievement`,qs.stringify(payload),{timeout:60000}) //with extra timeout
            .then(async response => {

                let showOtherTable = false

                //parse date
                for(let history of response.data.logList){
                    let rawDateSplit=history.createDate.split("T")
                    let dateSplit=rawDateSplit[0].split("-")
                    let timeSplit=rawDateSplit[1].split(":")
                    history.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                //calculate sales and commission of month
                let monthTotalSales=0
                let monthTotalCommission=0
                let monthTotalReferral=0
                let monthTotalCost=0
                let monthTotalProfitSharing=0

                let monthEmployeePGArray=[]
                let monthOutsideAgentPGArray=[]
                let monthOtherPGArray=[]
                
                for(let history of response.data.logList){
                    monthTotalSales+=history.value
                    monthTotalCost+=history.cost+history.referral                        
                    history.totalCommission=0                                                 
                    
                    //start counting
                    for(let historyDetail of history.detailList){
                        if(historyDetail.agent!=null){  //agent with commission
                            //count to see if personal or group comm is dominant for this agent of this history detail
                            let pgr=historyDetail.agent.tier.personalGoalReward
                            let ggr=historyDetail.agent.tier.groupGoalReward
                            let pReward=0
                            let gReward=0
                            if(pgr>0){
                                if(historyDetail.agent.monthlyPersonalSales>pgr){
                                    pReward=pgr
                                }else{
                                    pReward=historyDetail.agent.monthlyPersonalSales/historyDetail.agent.tier.personalGoal*pgr
                                }
                            }
                            if(ggr>0){
                                if(historyDetail.agent.monthlyGroupSales>ggr){
                                    gReward=ggr
                                }else{
                                    gReward=historyDetail.agent.monthlyGroupSales/historyDetail.agent.tier.groupGoal*ggr
                                }
                            }

                            //search existing array to see if this agent has accumulated personal reward total
                            let theFoundAgent=null
                            for(let ag of monthEmployeePGArray){
                                if(ag.agent.id==historyDetail.agent.id){ //found
                                    theFoundAgent=ag                                    
                                    break
                                }
                            }
                            if(theFoundAgent==null){
                                for(let ag of monthOutsideAgentPGArray){
                                    if(ag.agent.id==historyDetail.agent.id){ //found
                                        theFoundAgent=ag                                    
                                        break
                                    }
                                }
                            }
                            if(theFoundAgent==null){    //not found
                                theFoundAgent={
                                    agent:historyDetail.agent,
                                    agentRewardTotal:0,
                                    personalRewardTotal:0,
                                    groupRewardTotal:0,
                                    overridingRewardTotal:0,
                                    leadershipRewardTotal:0,
                                    referralRewardTotal:0,
                                    otherCommissionTotal:0,
                                    totalCommission:0,
                                }
                                if(historyDetail.agent.companyID!=response.data.companyDetail.id){    //outside agent
                                    monthOutsideAgentPGArray.push(theFoundAgent)
                                }else{
                                    monthEmployeePGArray.push(theFoundAgent)
                                }
                            }
                            
                            //record claimed person reward and group reward so that doesn't overpay

                            //check if the personal/group comm is off the roof
                            if(historyDetail.type=="personal"){
                                //normal add situation
                                theFoundAgent.personalRewardTotal+=historyDetail.commission
                                if(pReward>=gReward){
                                    history.totalCommission+=historyDetail.commission
                                    if(historyDetail.claimed==false){
                                        historyDetail.claimed=true
                                    }
                                }
                            }else if(historyDetail.type=="group"){
                                if(theFoundAgent.groupRewardTotal+historyDetail.commission>ggr){ //overshoot comm situation
                                    let remainingGroupComm=ggr-theFoundAgent.groupRewardTotal
                                    theFoundAgent.groupRewardTotal=ggr
                                    if(gReward>pReward){
                                        history.totalCommission+=remainingGroupComm
                                        historyDetail.commission=remainingGroupComm //change the history detail comm to display real value
                                        if(historyDetail.claimed==false){
                                            historyDetail.claimed=true
                                        }
                                    }
                                }else{  //normal add situation
                                    theFoundAgent.groupRewardTotal+=historyDetail.commission
                                    if(gReward>pReward){
                                        history.totalCommission+=historyDetail.commission
                                        if(historyDetail.claimed==false){
                                            historyDetail.claimed=true
                                        }
                                    }
                                }
                            }else{  //other types of commissions
                                history.totalCommission+=historyDetail.commission
                                if(historyDetail.type=="agentCommission"){
                                    theFoundAgent.agentRewardTotal+=historyDetail.commission
                                }else if(historyDetail.type=="overriding"){
                                    theFoundAgent.overridingRewardTotal+=historyDetail.commission
                                }else if(historyDetail.type=="leadership"){
                                    theFoundAgent.leadershipRewardTotal+=historyDetail.commission
                                }else{
                                    theFoundAgent.otherCommissionTotal+=historyDetail.commission                              
                                }
                                if(historyDetail.claimed==false){
                                    historyDetail.claimed=true
                                }
                            }
                        }else if(historyDetail.type=="referral"||historyDetail.type=="recommend"){   //referral history detail
                            if(historyDetail.referrer.tier!=null){   //is agent/affiliate of this company or other
                                //search existing array to see if this agent is already in there
                                let theFoundAgent=null
                                for(let ag of monthEmployeePGArray){
                                    if(historyDetail.referrer.tier!=null){
                                        if(ag.agent.id==historyDetail.referrer.tier.employeeDetail.id){ //found
                                            theFoundAgent=ag                                    
                                            break
                                        }
                                    }
                                }
                                if(theFoundAgent==null){
                                    for(let ag of monthOutsideAgentPGArray){
                                        if(historyDetail.referrer.tier!=null){
                                            if(ag.agent.id==historyDetail.referrer.tier.employeeDetail.id){ //found
                                                theFoundAgent=ag                                    
                                                break
                                            }
                                        }
                                    }
                                }
                                if(theFoundAgent==null){
                                    theFoundAgent={
                                        agent:historyDetail.referrer.tier.employeeDetail,
                                        agentRewardTotal:0,
                                        personalRewardTotal:0,
                                        groupRewardTotal:0,
                                        overridingRewardTotal:0,
                                        leadershipRewardTotal:0,
                                        referralRewardTotal:0,
                                        otherCommissionTotal:0,
                                        totalCommission:0,
                                    }

                                    if(historyDetail.referrer.tier.employeeDetail.companyID!=response.data.companyDetail.id){
                                        monthOutsideAgentPGArray.push(theFoundAgent)
                                    }else{
                                        monthEmployeePGArray.push(theFoundAgent)
                                    }
                                }
                                
                                theFoundAgent.referralRewardTotal+=historyDetail.referral
                            }else{  //not agent/affiliate of this company
                                //search existing array to see if this agent is already in there
                                let theFoundAgent=null
                                for(let ag of monthOtherPGArray){
                                    if(ag.agent.username==historyDetail.referrer.username){ //found
                                        theFoundAgent=ag                                    
                                        break
                                    }
                                }
                                if(theFoundAgent==null){
                                    theFoundAgent={
                                        agent:historyDetail.referrer,
                                        agentRewardTotal:0,
                                        personalRewardTotal:0,
                                        groupRewardTotal:0,
                                        overridingRewardTotal:0,
                                        leadershipRewardTotal:0,
                                        referralRewardTotal:0,
                                        otherCommissionTotal:0,
                                        totalCommission:0,
                                    }
                                    monthOtherPGArray.push(theFoundAgent)
                                }
                                theFoundAgent.referralRewardTotal+=historyDetail.referral
                                showOtherTable = true
                            }
                        }               
                    }
                }

                //count total commission of each agent
                for(let agent of monthEmployeePGArray){                        
                    agent.totalCommission=agent.agentRewardTotal+agent.overridingRewardTotal+agent.leadershipRewardTotal+agent.otherCommissionTotal
                    if(agent.personalRewardTotal>agent.groupRewardTotal){
                        agent.totalCommission+=agent.personalRewardTotal
                    }else{
                        agent.totalCommission+=agent.groupRewardTotal
                    }                        
                }
                for(let agent of monthOutsideAgentPGArray){                        
                    agent.totalCommission=agent.agentRewardTotal+agent.overridingRewardTotal+agent.leadershipRewardTotal+agent.otherCommissionTotal
                    if(agent.personalRewardTotal>agent.groupRewardTotal){
                        agent.totalCommission+=agent.personalRewardTotal
                    }else{
                        agent.totalCommission+=agent.groupRewardTotal
                    }                        
                }             
                
                //sort the agent by highest comm at top
                monthEmployeePGArray.sort((a,b) => b.totalCommission - a.totalCommission)
                //count monthTotalCommission
                for(let agent of monthEmployeePGArray){
                    if(agent.agent.companyID==response.data.companyDetail.id){
                        monthTotalCommission+=agent.totalCommission
                        monthTotalReferral+=agent.referralRewardTotal
                    }
                }

                monthTotalSales=monthTotalSales.toLocaleFixed(2)
                monthTotalCommission=monthTotalCommission.toLocaleFixed(2)
                monthTotalReferral=monthTotalReferral.toLocaleFixed(2)
                monthTotalCost=monthTotalCost.toLocaleFixed(2)
                monthTotalProfitSharing=((monthTotalSales-monthTotalCommission-monthTotalCost)*response.data.companyDetail.profitSharingPercentage/100).toLocaleFixed(2)

                //convert english to chinese
                for(let history of response.data.logList){
                    for(let historyDetail of history.detailList){
                        if(historyDetail.type=="agentCommission"){
                            if(history.courseType=="backend"){
                                historyDetail.type="成交奖金"
                            }else{
                                historyDetail.type="成交奖金"
                            }
                        }else if(historyDetail.type=="personal"){
                            historyDetail.type="绩效奖金"
                        }else if(historyDetail.type=="group"){
                            historyDetail.type="组织业绩"
                        }else if(historyDetail.type=="overriding"){
                            historyDetail.type="差额奖金"
                        }else if(historyDetail.type=="leadership"){
                            historyDetail.type="领导奖金"
                        }else if(historyDetail.type=="referral"){
                            historyDetail.type="流量奖金"
                        }else if(historyDetail.type=="recommend"){
                            historyDetail.type="推荐奖金"
                        }else if(historyDetail.type=="个人绩效Overflow"){
                            historyDetail.type="绩效Overflow"
                        }
                    }
                }

                this.setState({
                    loading:false,
                    isStaff:response.data.isStaff,
                    achievementData:response.data.logList,
                    companyDetail:response.data.companyDetail,
                    monthTotalSales:monthTotalSales,
                    monthTotalCommission:monthTotalCommission,
                    monthTotalReferral:monthTotalReferral,
                    monthTotalCost:monthTotalCost,
                    monthTotalProfitSharing:monthTotalProfitSharing,
                    monthEmployeePGArray:monthEmployeePGArray,
                    monthOutsideAgentPGArray:monthOutsideAgentPGArray,
                    monthOtherPGArray:monthOtherPGArray,
                    censoredColumn:response.data.censoredColumn,
                    showOtherTable :showOtherTable,
                    payToCompany:response.data.companyDetail.payToCompany,
                })
            })
            .catch(error =>{
                alert("Load achievement error\n"+error)
                this.setState({loading:false})
            })
    }

    handleChildLoading = (loading) => {
        // Update the parent state with data from the child
        this.setState({ loadingFromChild: loading });      
      };

    getSharingRecord=(page=1)=>{
        this.setState({loading:true})
        const payload={
            companyID:this.state.companyDetail.id,
            page:page,
            itemPerPage:10,
        }
        axios
            .post(`sales/getprofitsharingrecord`,qs.stringify(payload))
            .then(async response => {
                //parse date
                for(var profitSharingLog of response.data.profitSharingLogList){
                    var rawDateSplit=profitSharingLog.createDate.split("T")
                    var dateSplit=rawDateSplit[0].split("-")
                    var timeSplit=rawDateSplit[1].split(":")
                    profitSharingLog.createDate=dateSplit[2]+"."+dateSplit[1]+"."+dateSplit[0]+"  "+timeSplit[0]+":"+timeSplit[1]
                }

                this.setState({
                    loading:false,
                    profitSharingLogList:response.data.profitSharingLogList,
                    profitSharingModal:true,
                    profitSharingPageCount:response.data.pageCount,
                    profitSharingPage:page,
                })
            })
            .catch(error =>{
                if(error.response){
                    if(error.response.status==460){
                        alert("Your account was logged into another device")
                        this.setState({loading:false})
                        history.push("/")
                        return
                    }
                }
                alert("Get profit sharing log error\n"+error)
                this.setState({
                    loading:false,
                })
            })
    }
    handleProfitSharingLogPageClick=(data)=>{
        let selected = data.selected;
        this.getSharingRecord(selected+1)
    }

    checkCensored=(columnName)=>{
        for(let col of this.state.censoredColumn){
            if(col==columnName){
                return true
            }
        }
        return false
    }

    render(){
        return(
            <div>
                {
                    this.state.loading || this.state.loadingFromChild ?
                        <LoadingScreen />
                        : null
                }

                <Modal isOpen={this.state.remarkModal} toggle={()=>this.setState({remarkModal:false})} style={{maxWidth:500}}>
                    <div style={{padding:15,paddingTop:10,display:"flex",flexDirection:"column",alignItems:"center",borderRadius:5}}>
                        {this.state.remarkText!=null?this.state.remarkText.split("\n").map((line)=>{return(<div>{line}</div>)}):null}
                    </div>
                </Modal>

                <Modal isOpen={this.state.profitSharingModal} toggle={()=>this.setState({profitSharingModal:false})}>
                    <div style={{padding:25,borderRadius:10}}>
                        <div>
                            Profit Sharing Log
                        </div>
                        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                            {
                            this.state.profitSharingLogList.map((profitSharingLog)=>{
                                if(profitSharingLog.operator==null){
                                    return(
                                        <div style={{border:"solid 1px green",borderRadius:5,width:"100%",display:"flex",flexDirection:"column",padding:10,marginBottom:5}}>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",fontSize:12}}>
                                                <div>{profitSharingLog.description}</div>
                                                <div>{profitSharingLog.createDate}</div>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                                                <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",width:"100%",fontSize:12}}>
                                                    <div>Total Value: RM{profitSharingLog.totalValue.toLocaleFixed(2)}</div>
                                                    <div>Total Cost: RM{profitSharingLog.totalCost.toLocaleFixed(2)}</div>
                                                    <div>Total Commission: RM{profitSharingLog.totalCommission.toLocaleFixed(2)}</div>
                                                    <div>Total Remaining Profit: RM{profitSharingLog.totalRemainingProfit.toLocaleFixed(2)}</div>
                                                    <div>Profit Sharing Percentage: {profitSharingLog.profitSharingPercentage}%</div>

                                                </div>
                                                <div style={{display:"flex",flexDirection:"column",textAlign:"right",fontSize:13,whiteSpace:"nowrap"}}>
                                                    <div>Amount: RM{profitSharingLog.profitSharingAmount.toLocaleFixed(2)}</div>
                                                    <div style={{marginLeft:10}}>Balance: RM{profitSharingLog.balance.toLocaleFixed(2)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }else{
                                    return(
                                        <div style={{border:"solid 1px lightgrey",borderRadius:5,width:"100%",display:"flex",flexDirection:"column",padding:10,marginBottom:5}}>
                                            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%",fontSize:12}}>
                                                <div>Operator: {profitSharingLog.operator}</div>
                                                <div>{profitSharingLog.createDate}</div>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                                                <div style={{flex:1,display:"flex",flexDirection:"column"}}>
                                                    <div style={{fontSize:12}}>Payment Number: {profitSharingLog.paymentNumber}</div>
                                                    <div style={{fontSize:12,color:"grey"}}>
                                                        {profitSharingLog.description}
                                                    </div>
                                                </div>
                                                <div style={{marginLeft:5,textAlign:"right",fontSize:13}}>
                                                    <div>Amount: RM{profitSharingLog.profitSharingAmount.toLocaleFixed(2)}</div>
                                                    <div>Balance: RM{profitSharingLog.balance.toLocaleFixed(2)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }
                        </div>
                        <div>
                            {this.state.profitSharingPageCount > 0 && (
                                <div style={this.state.width > 600 ? {display: "flex", justifyContent: "flex-end", marginTop: 10} : {display: "flex", justifyContent: "flex-end", marginTop: 10, fontSize: "0.7rem"}}>
                                    <ReactPaginate
                                        disableInitialCallback={true}   //stop auto reload upon mount
                                        initialPage={this.state.profitSharingPage-1}
                                        previousLabel={'previous'}
                                        nextLabel={'next'}
                                        breakLabel={'...'}
                                        pageCount={this.state.profitSharingPageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handleProfitSharingLogPageClick}
                                        subContainerClassName={'pages pagination'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        forcePage={this.state.profitSharingPage-1}
                                    />
                                </div>
                            )}
                        </div>
                        <div style={{marginTop:10,width:"100%"}}>
                            <Button style={{}} onClick={()=>{
                                this.setState({profitSharingModal:false})
                            }}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>

    
                <NavigationBar currentPage="companyachievement" />
                <div style={{padding:10,display:"flex",flexDirection:"row",alignItems:"center"}}>
                    <div style={{fontWeight:"bold",fontSize:20,whiteSpace:"nowrap"}}>Company Achievement</div>
                    {
                    this.state.companyDetail?
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center",marginLeft:20,width:"100%"}}>
                            {
                            (GLOBAL.checkPermission("jj6p@C2E")&&this.state.viewAsIdentity=="Staff")||this.state.payToCompany==true?  //Can view agency wallet amount	
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",position:"relative"}}>
                                    <div style={{position:"absolute",width:"100%",height:"100%",pointerEvents:"none",backgroundColor:this.state.payToCompany==true?"transparent":"rgba(0, 162, 255,0.1)"}} />
                                    <div>Company Wallet: RM{this.state.companyDetail.wallet!=null?this.state.companyDetail.wallet.toLocaleFixed(2):"-"}</div>
                                    <Button style={{marginLeft:15}} onClick={()=>{
                                        this.setState({walletLogModal:true})
                                    }}>
                                        Wallet Records
                                    </Button>
                                    <BsFillInfoCircleFill style={{marginLeft:5,color:"skyblue",cursor:"pointer"}} onClick={()=>{
                                        this.setState({remarkModal:true,remarkText:"A virtual user account of the agent company to store awarded referral credit when agent company use company link to generate leads."})
                                    }}/>
                                    {/*
                                    <div style={{marginLeft:20}}>Profit Sharing Wallet: RM{this.state.companyDetail.profitSharingWallet.toLocaleFixed(2)}</div>
                                    <Button style={{marginLeft:15}} onClick={()=>{
                                        this.getSharingRecord()
                                    }}>
                                        Profit Sharing Records
                                    </Button>
                                    */}
                                </div>
                                :
                                null
                            }

                            <div style={{marginLeft:25}}>Pay To Company : </div>
                            {
                            this.state.payToCompany?
                                <AiOutlineCheckSquare style={{marginLeft: 5,color: "green",fontSize: "1.5rem",}}/>
                                :
                                <AiOutlineCloseSquare style={{marginLeft: 5,color: "red",fontSize: "1.5rem",}}/>
                            }

                            <div style={{flex:1}} />

                            {
                            GLOBAL.checkPermission("TdDl^&8u")? //can change company achievement view
                                <div style={{display:"flex",flexDirection:"row",gap:5,alignItems:"center"}}>
                                    <div style={{whiteSpace:"nowrap"}}>View As:</div>
                                    <Input type="select" value={this.state.viewAsIdentity} style={{backgroundColor:"gold"}} onChange={(e)=>{
                                        this.setState({viewAsIdentity:e.target.value},()=>{
                                            if(this.companyID){
                                                this.loadAgentCompanyAchievement(this.companyID)
                                            }else{
                                                this.loadAgentCompanyAchievement()
                                            }
                                        })
                                    }}>
                                        <option value="Staff">Staff</option>
                                        <option value="Agent Leader">Agent Leader</option>
                                    </Input>
                                </div>
                                :
                                null
                            }
                        </div>
                        :null
                    }
                </div>

                <div style={{fontSize:20,fontWeight:"bold",marginLeft:10,marginRight:10,marginBottom:10,display:"flex",flexDirection:"row",alignItems:"center"}}>
                    <Input type="month" name="Month" value={this.currentMonth} onChange={(e)=>{
                        this.currentMonth=e.target.value
                        let here = new URL(window.location.href)
                        here.searchParams.set('currentmonth',this.currentMonth)                            
                        window.history.pushState({},"",here)

                        if(this.companyID){
                            this.loadAgentCompanyAchievement(this.companyID)
                        }else{
                            this.loadAgentCompanyAchievement()
                        }
                    }} />
                    <Button color="primary" style={{marginLeft:10,color:"white",display:"flex",flexDirection:"center",alignItems:"center"}} onClick={()=>{
                        let date=new Date(this.currentMonth)
                        let d = date.getDate()
                        date.setMonth(date.getMonth() -1)
                        let monthString=""+(date.getMonth()+1)
                        if(monthString.length==1){
                            monthString="0"+monthString
                        }
                        let dateString=date.getFullYear()+"-"+monthString
                        this.currentMonth=dateString
                        let here = new URL(window.location.href)
                        here.searchParams.set('currentmonth',this.currentMonth)                            
                        window.history.pushState({},"",here)

                        if(this.companyID){
                            this.loadAgentCompanyAchievement(this.companyID)
                        }else{
                            this.loadAgentCompanyAchievement()
                        }
                    }}>
                        <MdNavigateBefore style={{color:"white",fontSize:22}} />
                    </Button>
                    <Button color="primary" style={{marginLeft:5,color:"white",display:"flex",flexDirection:"center",alignItems:"center"}} onClick={()=>{
                        let date=new Date(this.currentMonth)
                        let d = date.getDate()
                        date.setMonth(date.getMonth()+1)
                        let monthString=""+(date.getMonth()+1)
                        if(monthString.length==1){
                            monthString="0"+monthString
                        }
                        let dateString=date.getFullYear()+"-"+monthString
                        this.currentMonth=dateString
                        let here = new URL(window.location.href)
                        here.searchParams.set('currentmonth',this.currentMonth)                            
                        window.history.pushState({},"",here)

                        if(this.companyID){
                            this.loadAgentCompanyAchievement(this.companyID)
                        }else{
                            this.loadAgentCompanyAchievement()
                        }
                    }}>
                        <MdNavigateNext style={{color:"white",fontSize:22}} />
                    </Button>
                </div>

                <div style={{padding:10}}>
                    <div style={{fontSize:20,fontWeight:"bold"}}>Summary</div>
                    <div style={{display:"flex",flexDirection:"column",marginLeft:5,marginBottom:5}}>
                        <div style={{display:"flex",flexDirection:"row",marginBottom:5,alignItems:"row"}}>
                            <div style={{}}>Month Sales: RM{this.state.monthTotalSales}</div>
                            <div style={{marginLeft:20}}>Month Commission: RM{this.state.monthTotalCommission}</div>
                            <div style={{marginLeft:20}}>Month Referral: RM{this.state.monthTotalReferral}</div>
                            {/*<div style={{marginLeft:20}}>Month Cost: RM{this.state.monthTotalCost}</div>*/}
                            {/*<div style={{marginLeft:20}}>Month Profit Sharing: RM{this.state.monthTotalProfitSharing}</div>*/}
                            <div style={{flex:1}}/>
                        </div>
                    </div>
                    
                    <table style={{width:"100%",marginBottom:15}}>
                        <thead>
                            <tr>
                                <td style={{border:"1px solid lightgrey",textAlign:"center"}}>Agent</td>
                                <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#dedeff"}}>流量/推荐奖金</td> 
                                <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#f0faf0"}}>成交奖金</td>
                                <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#f0faf0"}}>绩效奖金</td>
                                {
                                this.checkCensored("Group")==false?
                                    <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#f0faf0"}}>组织业绩奖金</td>
                                    :
                                    null
                                }
                                <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#f0faf0"}}>差额奖金</td>
                                <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#f0faf0"}}>领导奖金</td>
                                <td style={{border:"1px solid lightgrey",textAlign:"center" ,backgroundColor:"#f0faf0"}}>Total Commission (RM)</td>
                            </tr>
                        </thead>
                        {
                        this.state.monthEmployeePGArray.map((employeePG)=>{                                    
                            return(
                                <tr style={{}}>
                                    <td style={{border:"1px solid lightgrey",padding:5}}>{employeePG.agent.username==employeePG.agent.name?employeePG.agent.username:employeePG.agent.username+" "+employeePG.agent.name} ({employeePG.agent.tier.name})</td>
                                    <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#dedeff"}}>{ employeePG.referralRewardTotal<=0? "-":employeePG.referralRewardTotal.toLocaleFixed(2)}</td>
                                    <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#f0faf0"}}>{employeePG.agentRewardTotal<=0? "-": employeePG.agentRewardTotal.toLocaleFixed(2)}</td>
                                    <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#f0faf0",
                                        color:this.checkCensored("Group")==true||employeePG.personalRewardTotal>employeePG.groupRewardTotal?"black":"lightgrey"}}>
                                        {
                                        employeePG.agent.tier.personalGoalReward<=0?
                                            "-"
                                            :
                                            employeePG.agent.tier.unlimitedPersonalGoalReward==false?
                                                employeePG.personalRewardTotal.toLocaleFixed(2)+" / "+employeePG.agent.tier.personalGoalReward.toLocaleFixed(2)
                                                :
                                                <div>{employeePG.personalRewardTotal.toLocaleFixed(2)} / &#8734; + {employeePG.otherCommissionTotal.toLocaleFixed(2)}</div>
                                        }
                                    </td>
                                    {
                                    this.checkCensored("Group")==false?
                                        <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,color:employeePG.groupRewardTotal>employeePG.personalRewardTotal?"black":"lightgrey"}}>
                                            {
                                            employeePG.agent.tier.groupGoalReward<=0?
                                                "-"
                                                :
                                                employeePG.groupRewardTotal.toLocaleFixed(2)+" / "+employeePG.agent.tier.groupGoalReward.toLocaleFixed(2)
                                            }
                                        </td>
                                        :
                                        null
                                    }
                                    <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#f0faf0"}}>{employeePG.overridingRewardTotal<=0? "-":employeePG.overridingRewardTotal.toLocaleFixed(2)}</td>
                                    <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#f0faf0"}}>{employeePG.leadershipRewardTotal<=0? "-":employeePG.leadershipRewardTotal.toLocaleFixed(2)}</td>
                                    <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#f0faf0"}}>{employeePG.totalCommission<=0? "-":employeePG.totalCommission.toLocaleFixed(2)}</td>
                                </tr>
                            )
                        })
                        }
                    </table>

                    {
                    this.state.viewAsIdentity=="Staff"&&this.state.monthOutsideAgentPGArray.length>0?
                        <div style={{width:"100%"}}>
                            <div>Outside Agent</div>
                            <table style={{width:"100%",marginBottom:15}}>
                                <thead>
                                    <tr>
                                        <td style={{border:"1px solid lightgrey",textAlign:"center"}}>Outside Agent</td>
                                        <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#dedeff"}}>流量/推荐奖金</td> 
                                        <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#f0faf0"}}>成交奖金</td>
                                        <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#f0faf0"}}>绩效奖金</td>
                                        {
                                        this.checkCensored("Group")==false?
                                            <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#f0faf0"}}>组织业绩奖金</td>
                                            :
                                            null
                                        }
                                        <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#f0faf0"}}>差额奖金</td>
                                        <td style={{border:"1px solid lightgrey",textAlign:"center",backgroundColor:"#f0faf0"}}>领导奖金</td>
                                        <td style={{border:"1px solid lightgrey",textAlign:"center" ,backgroundColor:"#f0faf0"}}>Total Commission (RM)</td>
                                    </tr>
                                </thead>
                                {
                                this.state.monthOutsideAgentPGArray.map((employeePG)=>{                                    
                                    return(
                                        <tr style={{}}>
                                            <td style={{border:"1px solid lightgrey",padding:5}}>{employeePG.agent.username==employeePG.agent.name?employeePG.agent.username:employeePG.agent.username+" "+employeePG.agent.name} ({employeePG.agent.tier.name})</td>
                                            <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#dedeff"}}>{ employeePG.referralRewardTotal<=0? "-":employeePG.referralRewardTotal.toLocaleFixed(2)}</td>
                                            <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#f0faf0"}}>{employeePG.agentRewardTotal<=0? "-": employeePG.agentRewardTotal.toLocaleFixed(2)}</td>
                                            <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#f0faf0",
                                                color:this.checkCensored("Group")==true||employeePG.personalRewardTotal>employeePG.groupRewardTotal?"black":"lightgrey"}}>
                                                {
                                                employeePG.agent.tier.personalGoalReward<=0?
                                                    "-"
                                                    :
                                                    employeePG.agent.tier.unlimitedPersonalGoalReward==false?
                                                        employeePG.personalRewardTotal.toLocaleFixed(2)+" / "+employeePG.agent.tier.personalGoalReward.toLocaleFixed(2)
                                                        :
                                                        <div>{employeePG.personalRewardTotal.toLocaleFixed(2)} / &#8734; + {employeePG.otherCommissionTotal.toLocaleFixed(2)}</div>
                                                }
                                            </td>
                                            {
                                            this.checkCensored("Group")==false?
                                                <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,color:employeePG.groupRewardTotal>employeePG.personalRewardTotal?"black":"lightgrey"}}>
                                                    {
                                                    employeePG.agent.tier.groupGoalReward<=0?
                                                        "-"
                                                        :
                                                        employeePG.groupRewardTotal.toLocaleFixed(2)+" / "+employeePG.agent.tier.groupGoalReward.toLocaleFixed(2)
                                                    }
                                                </td>
                                                :
                                                null
                                            }
                                            <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#f0faf0"}}>{employeePG.overridingRewardTotal<=0? "-":employeePG.overridingRewardTotal.toLocaleFixed(2)}</td>
                                            <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#f0faf0"}}>{employeePG.leadershipRewardTotal<=0? "-":employeePG.leadershipRewardTotal.toLocaleFixed(2)}</td>
                                            <td style={{border:"1px solid lightgrey",textAlign:"right",padding:5,backgroundColor:"#f0faf0"}}>{employeePG.totalCommission<=0? "-":employeePG.totalCommission.toLocaleFixed(2)}</td>
                                        </tr>
                                    )
                                })
                                }
                            </table>
                        </div>
                        :
                        null
                    }

                    {              
                    this.state.showOtherTable?                                                                             
                        <table style={{width:"46%",marginBottom:15}}>
                            <tr>
                                <td style={{border:"1px solid lightgrey",textAlign:"center",width:"40%"}}>Others</td>
                                <td style={{border:"1px solid lightgrey",textAlign:"center",width:"7%",backgroundColor:"#dedeff"}}>流量/推荐奖金</td>
                            </tr>    
                            {
                            this.state.monthOtherPGArray.map((otherPG) => {                                    
                                return(                                       
                                    <tr>
                                        <td style={{border:"1px solid lightgrey",padding:5,width:"40%"}}>
                                            {
                                            otherPG.agent.username!=otherPG.agent.name?
                                                otherPG.agent.username+" "+otherPG.agent.name
                                                :
                                                otherPG.agent.name
                                            }
                                        </td>
                                        <td style={{border:"1px solid lightgrey",textAlign:"right",width:"7%",padding:5,backgroundColor:"#dedeff"}}>
                                            { otherPG.referralRewardTotal <= 0 ? "-" : otherPG.referralRewardTotal.toLocaleFixed(2) }
                                        </td>                                        
                                    </tr>
                                )
                            })}
                        </table>
                        : 
                        null
                    }

                    <div style={{fontSize:20,fontWeight:"bold"}}>Details</div>
                    <div style={{marginBottom:5,display:"flex",flexDirection:"row",justifyContent:"flex-end"}}>
                        <Button color="light" style={{marginLeft:10,padding:0,paddingLeft:5,paddingRight:5,fontSize:12}} 
                            onClick={()=>{copyDiv(this.divRef)}}>                               
                            Copy
                        </Button>
                    </div>
                    <div ref={this.divRef} >
                        <div style={{display:"flex",flexDirection:"row",padding:5,marginBottom:10,
                            borderStyle:"solid",borderColor:"lightgrey",borderWidth:0,borderTopWidth:1,borderBottomWidth:1}}>
                            <div style={{flex:0.1}}>Date</div>
                            <div style={{flex:0.1}}>Invoice No.</div>
                            <div style={{flex:0.25}}>Agenda</div>
                            <div style={{flex:0.1}}>Referrer</div>
                            <div style={{flex:0.15}}>Agent</div>
                            <div style={{flex:0.1,textAlign:"right",paddingRight:10}}>Total Commission (RM)</div>
                            {/*<div style={{flex:0.1,textAlign:"right",paddingRight:10}}>Cost (RM)</div>*/}
                            <div style={{flex:0.1,textAlign:"right",paddingRight:10}}>Referral (RM)</div>
                            <div style={{flex:0.1,textAlign:"right",paddingRight:10}}>Value (RM)</div>
                        </div>
                        {
                        this.state.achievementData.map((item)=>{
                            return(
                                <div style={{width:"100%",marginBottom:20}}>
                                    <div style={{display:"flex",flexDirection:"row",padding:5,borderStyle:"solid",width:"100%",
                                        borderColor:"lightgrey",borderWidth:1,borderRadius:5}}>
                                        <div style={{flex:0.1}}>{item.createDate}</div>
                                        <div style={{flex:0.1}}>{item.invoiceNumber}</div>
                                        <div style={{flex:0.25,fontSize:13}}>{item.agenda}</div>
                                        <div style={{flex:0.1,fontSize:13}}>
                                            {item.referrerCopy!=null?item.referrerCopy:"-"}
                                        </div>
                                        <div style={{flex:0.15,fontSize:13}}>
                                            {
                                            item.employeeCopy!=null?
                                                item.employeeCopy
                                                :
                                                "-"
                                            }
                                        </div>
                                        <div style={{flex:0.1,textAlign:"right",paddingRight:10,color:item.claimed==true?"black":"grey"}}>
                                            {item.totalCommission.toLocaleFixed(2)}
                                        </div>
                                        {/*<div style={{flex:0.1,textAlign:"right",paddingRight:10}}>{item.cost.toLocaleFixed(2)}</div>*/}
                                        <div style={{flex:0.1,textAlign:"right",paddingRight:10}}>
                                            {item.referral.toLocaleFixed(2)}
                                        </div>
                                        <div style={{flex:0.1,textAlign:"right",paddingRight:10}}>{item.value.toLocaleFixed(2)}</div>
                                    </div>
                                    <div style={{width:"100%",padding:"5px 10px"}}>
                                        <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                                            <div style={{flex:0.15}}>Agent</div>
                                            <div style={{flex:0.1}}>Tier</div>
                                            <div style={{flex:0.1}}>Type</div>
                                            <div style={{flex:0.1,textAlign:"right"}}>Commission (RM)</div>
                                            <div style={{flex:0.1,textAlign:"right"}}>Referral (RM)</div>
                                            <div style={{flex:0.1,textAlign:"right"}}>Percentage (%)</div>
                                            <div style={{flex:0.2,textAlign:"center"}}>Remark</div>
                                        </div>
                                        {
                                        item.detailList.map((detail)=>{
                                            return(
                                                <div style={{display:"flex",flexDirection:"row",width:"100%",
                                                    color:detail.claimed==false?"lightgrey":"black"}}>
                                                    <div style={{flex:0.15}}>
                                                        {
                                                        detail.agentCopy!=null?
                                                            detail.agentCopy.split(',')[0]
                                                            :
                                                        detail.referrerCopy!=null?
                                                            detail.referrerCopy.split('<t>')[0]
                                                            :
                                                            "-"
                                                        }
                                                    </div>
                                                    <div style={{flex:0.1}}>
                                                        {
                                                        detail.agentCopy!=null?
                                                            detail.agentCopy.split(',')[1]
                                                            :
                                                        detail.referrerCopy!=null?
                                                                detail.referrerCopy.split('<t>')[1]!=null?
                                                                    detail.referrerCopy.split('<t>')[1]
                                                                    :
                                                                    "-"
                                                            :
                                                            "-"
                                                        }
                                                    </div>
                                                    <div style={{flex:0.1}}>{detail.type}</div>
                                                    <div style={{flex:0.1,textAlign:"right"}}>
                                                        {detail.commission!=null&&detail.commission!=0?detail.commission.toLocaleFixed(2):"-"}
                                                    </div>
                                                    <div style={{flex:0.1,textAlign:"right"}}>
                                                        {detail.referral!=null&&detail.referral!=0?detail.referral.toLocaleFixed(2):"-"}
                                                    </div>
                                                    <div style={{flex:0.1,textAlign:"right"}}>
                                                        {
                                                        detail.percentage>0?
                                                            detail.percentage.toLocaleFixed(2)
                                                            :
                                                            "-"
                                                        }
                                                    </div>
                                                    <div style={{flex:0.2,textAlign:"center"}}>
                                                        <BsFillInfoCircleFill style={{color:"skyblue",cursor:"pointer"}} onClick={()=>{
                                                            this.setState({
                                                                remarkModal:true,
                                                                remarkText:detail.remark,
                                                            })
                                                        }}/>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>

                { 
                this.state.walletLogModal && (
                    <>                                                 
                        <WalletLog
                            sendLoadingToParent={this.handleChildLoading}
                            company={this.state.companyDetail.id}
                            isOpen={this.state.walletLogModal}
                            toggle={() => this.setState({ walletLogModal: false, loading: false })}
                        />
                    </>
                )
                }
            </div>
        )
    }
}

let styles={

}
